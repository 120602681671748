import React, { useEffect, useState } from "react";

// web3
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { useCapsuleNFTContract } from "hooks/useContract"

import { useContract } from "hooks/useContract"

// abi
import OOABI from "contracts/OO.json"

// utils
import {
  OOContractAddress,
  OOCapsuleNFTAddress,
  MainChainId,
  ZeroAddress
} from 'utils'

// metamask signin
import SignInButton from "app/SignInButton.js"

// oranges img
import oranges from "img/oranges.png"
import MintButton from "./mint/MintButton";
import BurnButton from "./burn/BurnButton";

export default function MainApp() {

  const { account, library } = useWeb3ReactCore()

  const [userOwnedOO, setUserOwnedOO] = useState(0)
  const [userOOIdArray, setUserOOIdArray] = useState([])
  const [totalOO, setTotalOO] = useState("?")

  const OOContract = useCapsuleNFTContract(OOCapsuleNFTAddress, true, { active: false })
  const OOOwnerContract = useContract(
    OOContractAddress,
    OOABI.networks[MainChainId]?.abi,
    true,
    { active: false }
  )

  useEffect(() => {
    const getUserOOBalance = async () => {
      // get the amount of OO owned by the user
      const amountOwned = parseInt(await OOContract.balanceOf(account))

      setUserOwnedOO(
        (library && account)
          ?
            amountOwned
          :
            0
      )

      return
    }

    const getTotalOO = async () => {
      // get the total amount of OO in circ
      const totalAmount = parseInt(await OOContract.totalSupply())

      setTotalOO(
        (library && account)
          ?
            `${totalAmount}`.padStart(3, '0')
          :
            "XXXX"
      )

      return
    }

    if (library && account) {
      getUserOOBalance()
      getTotalOO()
    }
  }, [account, library])

  useEffect(() => {
    const getUserOOIDs = async () => {
      // get the IDs of all the user owned OO
      if (userOwnedOO > 0) {
        const idsOwned = []

        for (var i = 0; i < userOwnedOO; i++) {
          idsOwned.push(parseInt(await OOContract.tokenOfOwnerByIndex(account, i)))
        }

        idsOwned.sort(function(a, b) {
          return a - b;
        })

        setUserOOIdArray(
          (library && account)
            ?
              [...idsOwned]
            :
              []
        )
      }

      return
    }

    if (account) {
      setUserOOIdArray(["Loading..."])
      getUserOOIDs()
    } else {
      setUserOOIdArray([])
    }
  }, [account, userOwnedOO])

  return (
  <div>
    <h1>Ordinary Oranges</h1>
    <img style={{width: '500px'}} src={oranges}/>

    <h2>Concept</h2>
    <p>The 256 Ordinary Oranges are all unique satoshi inscriptions on the Bitcoin network, viewable and tradable on the Ethereum blockchain.</p>
    <p>At any time, an Ordinary Orange holder can burn their NFT on the Ethereum Network to receive their unique inscription on the Bitcoin Network.</p>
    <p>Ordinary Oranges are a CC0, zero royalty project that can be traded on any Ethereum NFT Exchange.</p>
    <p>Check each Ordinary Orange inscription <a href="/inscriptions" rel="noopener noreferrer">here.</a></p>

    <h2>Mint</h2>
    <p>{totalOO}/256 exist in circulation today.</p>
    <SignInButton/>
    <MintButton/>
    
    <h2>Exchanges</h2>
    <li>&#128674; OpenSea - <a href='https://opensea.io/collection/ordinary-oranges'>https://opensea.io/collection/ordinary-oranges</a></li>


    <h2>Burn</h2>
    <p>You can burn your Ordinary Orange to recieve your Bitcoin inscription.</p>
    <BurnButton/>

    <h2>Learn More</h2>
    
    <h4>Ordinary Oranges</h4>
    
    <li>Code - <a href='https://github.com/capsulenft/ordinary-oranges'>https://github.com/capsulenft/ordinary-oranges</a></li>
    <li>Inscriptions - <a href="/inscriptions" rel="noopener noreferrer">https://ordinaryorangesnft.io/inscriptions</a></li>
    <li>Tokenomics - <a href="/tokenomics" rel="noopener noreferrer">https://ordinaryorangesnft.io/tokenomics</a></li>
    

    <h4>Bitcoin Inscription</h4>
    
    <li>Ordinal Website - <a href='https://ordinals.com/'>https://ordinals.com</a></li>
    <li>Ordinal Docs - <a href='https://docs.ordinals.com/'>https://docs.ordinals.com</a></li>

    <h4>Capsule</h4>
    
    <li>Capsule Website - <a href='https://capsulenft.com'>https://capsulenft.com</a></li>
    <li>Capsule Docs - <a href='https://docs.capsulenft.com'>https://docs.capsulenft.com</a></li>
    <li>Capsule Twitter - <a href='https://twitter.com/capsulenft'>https://twitter.com/capsulenft</a></li>
    <li>Capsule Discord - <a href='https://discord.gg/invite/capsulenft'>https://discord.gg/invite/capsulenft</a></li>

    <h4>Socials</h4>
    
    <li>Twitter - <a href='https://twitter.com/ordinaryoranges'>https://twitter.com/ordinaryoranges</a></li>
    <li>Discord - <a href='https://discord.gg/invite/capsulenft'>https://discord.gg/invite/capsulenft</a></li>

    <br/><br/><br/><br/>

    <p>Powered by <a href="https://capsulenft.com" target="_blank" rel="noopener noreferrer">Capsule</a></p>
  </div>
  );
}
