import React, { useState, useEffect } from "react"

// web3
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { useContract } from "hooks/useContract"

// dollar store contract info
import OOABI from "contracts/OO.json"

// utils
import {
  OOMintFee,
  OOContractAddress,
  parseEther,
  formatEther,
  MainChainId
} from 'utils'

export default function MintButton(props) {
  const { disabled } = props

  const { account, library } = useWeb3ReactCore()

  const OOContract = useContract(
    OOContractAddress,
    OOABI.networks[MainChainId]?.abi,
    true,
    { active: false }
  )

  const [errorMessage, setErrorMessage] = useState(null)

  const createCapsule = async () => {
    try {
      if (!library || !account) {
        setErrorMessage("Sign in first")
        return
      }

      const userBalance = await library
        .getBalance(account)
        .then((balance) => {
          return formatEther(balance.toString())
        })
        .catch(() => {
          return null
        })

      if (parseFloat(userBalance) < 1) {
        setErrorMessage("1 ETH Required")
      } else {
        const tx = await OOContract?.mint(
          {
            value: parseEther(OOMintFee)
          }
        )
        return tx
      }
    } catch (e) {
      if (e.toString().includes("mint-is-not-enabled")) {
        setErrorMessage("Minting is not available yet for Ordinary Oranges.")
      } else {
        setErrorMessage("Error minting an Ordinary Orange.")
      }
      console.error('Failure minting an Ordinary Orange', e)
      return null
    }
  }

  return disabled ?
      (
        <button>
          Sold Out
        </button>
      )
    :
      (
        <>
          <button
            onClick={async () => createCapsule()}
          >
            Mint an Ordinary Orange - 1 ETH
          </button>
          <div
            className="errorMessage"
            style={{
              color: 'red'
            }}
          >
            {errorMessage}
          </div>
        </>
      )
}
