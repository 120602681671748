import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from "ethers"

import { BigNumber } from "@ethersproject/bignumber"

export default function UserBalance() {
  const { account, active, error, library } = useWeb3React()

  const [ethBalance, setEthBalance] = useState("?")

  useEffect(() => {
    const getUserETHBalance = async () => {
      setEthBalance(
        parseInt(
          (await library.getBalance(account)).div(BigNumber.from(10).pow(14)).toString()
        ) / 10000
      )

      return
    }

    if (library && account) {
      getUserETHBalance()
    }
  }, [account, library])


  return (
    <div
      style={{
        display: 'inline-grid'
      }}
    >
      {(active) ? (
        <div
          className="errorMessage"
          style={{
            display: 'inline'
          }}
        >
          
          <span className="material-symbols-outlined"
            style={{
              verticalAlign: 'middle',
              color: "green"
            }}
          >
            check_circle
          </span>
          {account === null
            ? '-'
            : account
            ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
            : ''}
          {' -'} {ethBalance} ETH
        </div>
      ) :
      (!active && error) ? (
        'Error signing in'
      ) : '' }
    </div>
  )
}
