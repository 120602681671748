import { ethers } from "ethers"
import { Contract } from "@ethersproject/contracts"
import { AddressZero } from "@ethersproject/constants";

import CapsuleNFT from 'contracts/Capsule.json'

// parse amount to ether amount with ethers
export function parseEther(
  input
) {
  return ethers.utils.parseEther(input)
}

// parse amount of Ether returning decimals
export function formatEther(
  input
) {
  return ethers.utils.formatEther(input)
}

// account is not optional
export function getSigner(
  library,
  account
) {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library,
  account
) {
  return account ? getSigner(library, account) : library
}

export function getContract(address, ABI, library, account) {
  if (address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

export function getContractDirect(address, abi, library) {
  if (!address || !abi || !library) {
    return null
  }
  return new Contract(
    address,
    abi,
    library
  )
}

export function getGeneralCapsuleNFTContractDirect(address, chainId, library) {
  return getContractDirect(address,
    CapsuleNFT.networks[chainId] ?
      CapsuleNFT.networks[chainId].abi :
      undefined,
    library
  )
}

export function shortenAddress(address) {
  return address ?
      `${address.substring(0, 6)}...${address.substring(address.length - 4, address.length)}`
    :
      null
}

export function getEtherscanLinkWithHash(hash) {
  return `https://${(MainChainId === 1 ? '' : 'rinkeby.')}etherscan.io/tx/${hash}`
}

// mainnet: 1
export const MainChainId = 1
export const OOMintFee = "1"
export const ZeroAddress = AddressZero
export const OOContractAddress = "0x91f020c259c119b10eac5eb283e3cf829edce1f3"
export const OOCapsuleNFTAddress = "0x1b41F57D52FE6dB3a63bceB4E8845c0F9F31f859"
