import React from "react";

export default function TokenomicsPage() {

  return (
  <div>
    <h1>Ordinary Oranges</h1>
    <h4>Inscriptions</h4>
    <a href="/" rel="noopener noreferrer">Home</a>
    
    <br/><br/><br/><br/>
    
    <h2>Team</h2>
    <li>16 Ordinary Oranges - Capsule Team</li>

    <h2>Giveaways</h2>
    <li>6 Ordinary Oranges - Dollar Store Kids Ecosystem</li>
    <li>2 Ordinary Oranges - Zen Acadamy</li>
    <li>8 Ordinary Oranges - Other Partnerships</li>

    <h2>Public</h2>
    <li>224 Ordinary Oranges - Openly Available to Mint</li>

    <br/><br/><br/><br/>

    <a href="/" rel="noopener noreferrer">Home</a>
    <p>Ordinary Oranges - Powered by <a href="https://capsulenft.com" target="_blank" rel="noopener noreferrer">Capsule</a></p>
  </div>
  );
}
