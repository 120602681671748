import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";

import MainPage from "./app/MainPage";
import InscriptionPage from "./app/InscriptionPage";
import TokenomicsPage from "./app/TokenomicsPage";

var hist = createBrowserHistory();

const getLibrary = function (provider) {
  return new Web3Provider(provider, "any")
}

const Web3App = props => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Router history={hist}>
      <Switch>
        <Route path="/tokenomics" component={TokenomicsPage} />
        <Route path="/inscriptions" component={InscriptionPage} />
        <Route path="/" component={MainPage} />
      </Switch>
    </Router>
  </Web3ReactProvider>
)
// 
ReactDOM.render(
  <Web3App/>,
  document.getElementById("root")
);
