import React, { useEffect, useState } from "react";

// web3
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { useCapsuleNFTContract } from "hooks/useContract"

import { useContract } from "hooks/useContract"

// abi
import OOABI from "contracts/OO.json"

// utils
import {
  OOContractAddress,
  OOCapsuleNFTAddress,
  MainChainId,
  ZeroAddress
} from 'utils'

// metamask signin
import SignInButton from "app/SignInButton.js"

// oranges img
import oranges from "img/oranges.png"


export default function BurnButton() {

  const { account, library } = useWeb3ReactCore()

  const [userOwnedOO, setUserOwnedOO] = useState(0)
  const [userOOIdArray, setUserOOIdArray] = useState([])
  const [transactionPending, setTransactionPending] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const OOContract = useCapsuleNFTContract(OOCapsuleNFTAddress, true, { active: false })
  const OOOwnerContract = useContract(
    OOContractAddress,
    OOABI.networks[MainChainId]?.abi,
    true,
    { active: false }
  )

  const ooidInputId = "oo-id-to-burn"
  const [ooidInput, setooidInput] = useState('');

  const oobtcAddressInputId = "oo-btc-address"
  const [oobtcAddressInput, setoobtcAddressInput] = useState('');

  const approveOO = async () => {
    if (!account || !library) {
      setErrorMessage("Sign in above")
      return
    }

    if (ooidInput.length < 1) {
      setErrorMessage("Input your ID above")
      return
    }

    try {
      var id = ooidInput || ""
      const tx = await OOContract.approve(OOContractAddress, id)
    } catch (e) {
      if (e.toString().includes("ERC721: approve caller is not token owner or approved for all")) {
        setErrorMessage("You don't own that Ordinary Orange")
      } else {
        setErrorMessage("Could not approve your Ordinary Orange. Check the web console for more info")
      }
      console.error('Could not approve your Ordinary Orange', e)
      return null
    }
  }

  const burnOO = async () => {
    if (!account || !library) {
      setErrorMessage("Sign in above")
      return
    }

    if (ooidInput.length < 1 || oobtcAddressInput.length < 1) {
      setErrorMessage("Input your ID and receiving Bitcoin address above")
      return
    }

    try {
      var id = ooidInput || ""
      var btcAddress = oobtcAddressInput || ""
      const tx = await OOOwnerContract.burn(id, btcAddress)
    } catch (e) {
      if (e.toString().includes("ERC721: transfer from incorrect owner")) {
        setErrorMessage("You don't own that Ordinary Orange")
      } else if (e.toString().includes("execution reverted: ERC721: transfer caller is not owner nor approved")) {
        setErrorMessage("You didn't approve that Ordinary Orange with the burn contract")
      } else {
        setErrorMessage("Could not burn your Ordinary Orange. Check the web console for more info")
      }
      console.error('Could not burn your Ordinary Orange', e)
      return null
    }
  }

  useEffect(() => {
    const getUserOOBalance = async () => {
      // get the amount of OO owned by the user
      const amountOwned = parseInt(await OOContract.balanceOf(account))

      setUserOwnedOO(
        (library && account)
          ?
            amountOwned
          :
            0
      )

      return
    }

    if (library && account) {
      getUserOOBalance()
    }
  }, [account, library])

  useEffect(() => {
    const getUserOOIDs = async () => {
      // get the IDs of all the user owned OO
      if (userOwnedOO > 0) {
        const idsOwned = []

        for (var i = 0; i < userOwnedOO; i++) {
          idsOwned.push(parseInt(await OOContract.tokenOfOwnerByIndex(account, i)))
        }

        idsOwned.sort(function(a, b) {
          return a - b;
        })

        setUserOOIdArray(
          (library && account)
            ?
              [...idsOwned]
            :
              []
        )
      }

      return
    }

    if (account) {
      setUserOOIdArray(["Loading..."])
      getUserOOIDs()
    } else {
      setUserOOIdArray([])
    }
  }, [account, userOwnedOO])

  return (
    <>
      <p>You own Ordinary Orange(s): {userOOIdArray.toString()}</p>
      <label htmlFor={ooidInputId}>Input the ID of the Ordinary Orange to burn here:</label>
      <input id={ooidInputId} type="text" value={ooidInput} onInput={e => setooidInput(e.target.value)}></input>
      <br/>
      <label htmlFor={oobtcAddressInputId}>Input your Bitcoin Address here:</label>
      <input id={oobtcAddressInputId} type="text" value={oobtcAddressInput} onInput={e => setoobtcAddressInput(e.target.value)}></input>
      <br/>
      <button
        onClick={async () => approveOO()}
      >
        Approve your Ordinary Orange For Use
      </button>
      <br/>
      <button
        onClick={async () => burnOO()}
      >
        Burn Ordinary Orange {ooidInput} to {oobtcAddressInput}
      </button>
      <div
        className="errorMessage"
        style={{
            color: 'red'
        }}
        >
        {errorMessage}
      </div>
    </>
  );
}
