import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { injected } from 'connectors/connectors.js'
import { useEagerConnect, useInactiveListener } from 'hooks/web3ConnectorHooks.js'

import UserBalance from './UserBalance'

import Metamask from "img/metamask.png"

const SignInButton = function (props) {
  const context = useWeb3React()
  const { connector, activate, deactivate, active, error } = context

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  const currentConnector = injected
  const connected = currentConnector === connector

  return (
    <div>
      {!connected && (
        <div>
          <button
            onClick={() => {
              setActivatingConnector(currentConnector)
              activate(injected)
            }}
          >
            <img
              src={Metamask}
              style={{
                maxHeight: '32px',
                verticalAlign: 'middle'
              }}
              alt="Metamask Wallet Login"
            />
            Login with Metamask
            <img
              src={Metamask}
              style={{
                maxHeight: '32px',
                verticalAlign: 'middle'
              }}
              alt="Metamask Wallet Login"
            />
          </button>
        </div>
      )}
    {(active) && (
      <div
        style={{
        }}
      >
        <button
          onClick={() => {
            // sign out button
            deactivate()
          }}
        >
          <img
            src={Metamask}
            style={{
              maxHeight: '32px',
              verticalAlign: 'middle'
            }}
            alt="Metamask Wallet Log Out"
          />
          Log Out
          <img
            src={Metamask}
            style={{
              maxHeight: '32px',
              verticalAlign: 'middle'
            }}
            alt="Metamask Wallet Log Out"
          />
        </button>
        <div>
          <UserBalance/>
        </div>
      </div>
    )}
    {(error) && (
      <div
        style={{
        }}
      >
        <button
          onClick={() => {
            // wallet connect error button
            deactivate()
          }}
        >
          Wallet Connection Error Try again
        </button>
        <div>
          <p
            className="errorMessage"
            style={{
              color: 'red',
              marginTop: '0px'
            }}
          >
            Error - Try again
          </p>
        </div>
      </div>
    )}
    </div>
  )
}

export default SignInButton
