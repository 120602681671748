import { useMemo } from 'react'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { ethers } from "ethers"
import {
  getContract
} from 'utils'

// contract ABIs
import CapsuleNFTContract from "contracts/Capsule.json"

// returns null on errors
export function useContract(address, ABI, withSignerIfPossible, useNode) {
    let { library, account } = useWeb3ReactCore()

    if (useNode.active) {
      const chainId = useNode.chainId
      let url = getNodeUrl(chainId)

      if (url) {
        library = new ethers.providers.JsonRpcProvider(url, chainId)
      }
    }

    return useMemo(() => {
        if (!address || !ABI || !library) return null
        try {
            return getContract(address, ABI, library,
              withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, library, account, withSignerIfPossible, useNode])
}

export function useCapsuleNFTContract(tokenAddress, withSignerIfPossible, useNode) {
    let { chainId } = useWeb3ReactCore()

    if (useNode.active) {
      chainId = useNode.chainId
    }

    return useContract(
      tokenAddress,
      chainId ?
        (CapsuleNFTContract.networks[chainId] ?
          CapsuleNFTContract.networks[chainId].abi :
          undefined
        ) :
        undefined,
      withSignerIfPossible,
      useNode)
}

export function getNodeUrl(chainId) {
  let url = null

  switch(chainId) {
    case 1:
      url = ''
      break;
    case 4:
      url = ''
      break;
    default:
      url = ''
  }

  return url
}
